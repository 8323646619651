var render = function () {
  var _vm$issue$pgn, _vm$issue$fmiDescript, _vm$issue$summary, _vm$issue$description, _vm$issue$action, _vm$issue$spareParts;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IssueMetaMoleculeStyled', [_c('HeaderWithIconAtom', {
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('TalpaIcon', {
          staticClass: "icon",
          attrs: {
            "scope": 'Misc',
            "name": 'BulbIcon'
          }
        })];
      },
      proxy: true
    }, {
      key: "title",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.issue.label ? _vm.issue.label : _vm.$t('messages.noDataAvailable')))];
      },
      proxy: true
    }])
  }), _c('SubHeaderStyled', [_c('KeyValueV2Atom', {
    attrs: {
      "label": 'SPN',
      "value": _vm.issue.spn
    }
  }), _c('KeyValueV2Atom', {
    attrs: {
      "label": 'PGN',
      "value": (_vm$issue$pgn = _vm.issue.pgn) !== null && _vm$issue$pgn !== void 0 ? _vm$issue$pgn : '-'
    }
  }), _c('KeyValueV2Atom', {
    attrs: {
      "label": 'FMI',
      "value": _vm.issue.fmi
    }
  }), _c('KeyValueV2Atom', {
    attrs: {
      "label": _vm.$t('maintenance.fmiDescription'),
      "value": (_vm$issue$fmiDescript = _vm.issue.fmiDescription) !== null && _vm$issue$fmiDescript !== void 0 ? _vm$issue$fmiDescript : '-'
    }
  })], 1), _c('TextSectionAreaStyled', [_c('TextSectionMolecule', {
    staticClass: "summary",
    attrs: {
      "title": _vm.$t('maintenance.summary'),
      "text": (_vm$issue$summary = _vm.issue.summary) !== null && _vm$issue$summary !== void 0 ? _vm$issue$summary : _vm.$t('messages.noDataAvailable')
    }
  }), _c('TextSectionMolecule', {
    staticClass: "details",
    attrs: {
      "title": _vm.$t('maintenance.details'),
      "text": (_vm$issue$description = _vm.issue.description) !== null && _vm$issue$description !== void 0 ? _vm$issue$description : _vm.$t('messages.noDataAvailable')
    }
  }), _c('TextSectionMolecule', {
    staticClass: "action",
    attrs: {
      "isMarkdown": true,
      "title": _vm.$t('maintenance.recommendedAction'),
      "text": (_vm$issue$action = _vm.issue.action) !== null && _vm$issue$action !== void 0 ? _vm$issue$action : _vm.$t('messages.noDataAvailable')
    }
  }), _c('TextSectionMolecule', {
    staticClass: "spare",
    attrs: {
      "isMarkdown": true,
      "title": _vm.$tc('maintenance.spareParts', 2),
      "text": (_vm$issue$spareParts = _vm.issue.spareParts) !== null && _vm$issue$spareParts !== void 0 ? _vm$issue$spareParts : _vm.$t('messages.noDataAvailable')
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }